<template>
  <div id="map" ref="mapContainer"></div>
</template>

<script>
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import settingMap from "@/utils/map/settingMap";
import { deleteMapContributors } from "@/utils/map/deleteMapContributors";
import {
  getSvgWithColor,
  svgStringToImageSrc,
} from "@/utils/map/deleteMapContributors";
export default {
  props: ["performer"],
  data() {
    return {
      map: null,
      markerCar: null,
      center: [this.performer.performer_lng, this.performer.performer_lat],
    };
  },
  mounted() {
    this.refresh();
    this.createMarker();
    deleteMapContributors();
  },
  methods: {
    refresh() {
      this.map = new maplibregl.Map({
        container: this.$refs.mapContainer,
        style: settingMap.map.style,
        zoom: settingMap.map.zoom,
        maxZoom: settingMap.map.maxZoom,
        minZoom: settingMap.map.minZoom,
        center: this.center,
      });
      this.map.on("load", () => {
        this.map.addSource("map", {
          type: "vector",
          tiles: ["https://mtile.gram.tj/data/v3/{z}/{x}/{y}.pbf"],
          cluster: true,
        });
        this.createMarker();
      });
    },
    createMarker() {
      const popup = new maplibregl.Popup({ offset: 25 }).setText(
        `Cтатус: ${this.performer.state_status}`
      );
      const svgString = getSvgWithColor(
        this.performer.status_color,
        "performer",
        this.performer.direction
      );
      const svgImage = new Image();
      svgImage.src = svgStringToImageSrc(svgString);
      svgImage.style.cursor = "pointer";
      svgImage.style.width = "25px";
      svgImage.style.height = "25px";
      svgImage.onload = () => {
        const imageName = `custom-svg-performer${this.performer.id}`;
        if (this.map.hasImage(imageName)) {
          this.map.removeImage(imageName);
        }
        this.map.addImage(imageName, svgImage);

        const el = document.createElement("div");
        el.appendChild(svgImage);
        el.dataset.orderID = this.performer.id;

        this.markerCar = new maplibregl.Marker({ element: el })
          .setLngLat([
            this.performer.performer_lng,
            this.performer.performer_lat,
          ])
          .setPopup(popup)
          .addTo(this.map);
      };
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  },
};
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: calc(100vh - 98px) !important;
}
</style>
